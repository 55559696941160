<template>
	<div class="box">
		<SeasonInput v-model="season_selected" @change="loadFertilite" />

		<CustomTable
			v-if="season_selected"
			id_table="fertilite"
			:hrefsRoutes="config_table_hrefs"
			:items="season_mare_stallions"
			:busy.sync="table_busy"
			primaryKey="key"
		/>
	</div>
</template>

<script type="text/javascript">
    import GynecologieMixin from "@/mixins/Gynecologie.js"

	export default {
		name: 'Fertilite',
		mixins: [GynecologieMixin],
		data () {
			return {
				table_busy: true,
				season_selected: null,
				season_mare_stallions: [],
				config_table_hrefs: {
					'horse_name': {
						routeUniqueName: 'horseFiche',
						routeName: 'horseFiche',
						params: {
							horse_id: 'horse_id'
						}
					}
				},
			}
		},

		methods: {
			async loadFertilite() {
				this.table_busy = true

				this.season_mare_stallions = await this.loadStatFertilite(this.season_selected.id)

				this.table_busy = false
			},
		},

		components: {
			CustomTable : () => import('GroomyRoot/components/Table/CustomTable'),
			SeasonInput : () => import('@/components/Inputs/SeasonInput'),
		}
	}

</script>